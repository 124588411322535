import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { WagonInformation } from "../../../../models/api-railorder";

@Component({
  selector: 'app-wagon-information-list',
  templateUrl: './wagon-information-list.component.html',
  styleUrls: ['./wagon-information-list.component.scss']
})
export class WagonInformationListComponent implements OnInit {

  @Input() formGroup: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.addNewLine();
  }
  
  protected addNewLine(item?: WagonInformation): void {
    const itemGroup: FormGroup = this.fb.group({});
    if (item) {
      itemGroup.addControl('test_0', new FormControl(''));
      itemGroup.addControl('test_1', new FormControl(''));
      itemGroup.addControl('test_2', new FormControl(''));
      itemGroup.addControl('test_3', new FormControl(''));
      itemGroup.addControl('test_4', new FormControl(''));
      itemGroup.addControl('test_5', new FormControl(''));
      itemGroup.addControl('test_6', new FormControl(''));
    } else {
      itemGroup.addControl('test_0', new FormControl());
      itemGroup.addControl('test_1', new FormControl());
      itemGroup.addControl('test_2', new FormControl());
      itemGroup.addControl('test_3', new FormControl());
      itemGroup.addControl('test_4', new FormControl());
      itemGroup.addControl('test_5', new FormControl());
      itemGroup.addControl('test_6', new FormControl());
    }
    this.wagonInformationList.push(itemGroup);    
  }

  protected removeLine(idx: number): void {
    if (this.wagonInformationList.length > 1) {
      this.wagonInformationList.removeAt(idx);
    }
  }

  protected get wagonInformationList(): FormArray {
    return this.formGroup?.get('wagonInformationList') as FormArray;
  }
}