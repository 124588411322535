import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-dangerous-goods',
  templateUrl: './dangerous-goods.component.html',
  styleUrls: ['./dangerous-goods.component.scss']
})
export class DangerousGoodsComponent {

  @Input() formGroup: FormGroup;

}