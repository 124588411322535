import { Component, Injector, Input, OnInit } from '@angular/core';
import { SectionBase } from '../section.base';
import { SectionName } from '../../enums/order-enums';
import { CommercialSpecification, RailOrder } from '../../models/api-railorder';
import { NewOrderMainComponent } from '../../new-order-main/new-order-main.component';
import { Form, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DynamicStorageService } from '../../service/dynamic-session-storage.service';
import { KeyValuePair, RAILORDER_COMMERCIAL_CURRENCY_LISTNAME } from './../../models/api-dynamic-storage'
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-new-order-commercial',
  templateUrl: './new-order-commercial.component.html',
  styleUrls: ['../../new-order-main/new-order-main.component.scss',
              './new-order-commercial.component.scss']
})

export class NewOrderCommercialComponent  extends SectionBase implements OnInit {
onChangeAdditionalCommercialInformationValueOfDeliveryCurrency($event: Event) {
throw new Error('Method not implemented.');
}
onChangeAdditionalCommercialInformationValueOfCommodityCurrency($event: Event) {
throw new Error('Method not implemented.');
}
  @Input() currentSectionName: SectionName;
  @Input() railOrder: RailOrder;

  protected SectionName = SectionName;
  protected parent: NewOrderMainComponent;
  protected formGroup: FormGroup;
  protected isAdditionalPrepaymentInput: boolean;
  protected isCommercialSpecification: boolean;
  protected isAdditionalCommercialInformation: boolean;
  protected isAnnotationOfConsignorDescription: boolean;
  protected isDbInternal: boolean;
  protected isSectionalInvoicing: boolean;
  protected commercialSpecifications: CommercialSpecification[] = [];
  protected currencyList : KeyValuePair[] = [];
  

  private RAILORDER_COMMERCIAL_SPECIFICATION_LISTNAME: string = 'RAILORDER_COMMERCIAL_SPECIFICATION_LIST';

  constructor(private injector: Injector, private formBuilder: FormBuilder, private dynamicStorageService: DynamicStorageService) {
    super();
    this.createMockup();
    this.parent = this.injector.get<NewOrderMainComponent>(NewOrderMainComponent);
    this.createForm();
  }
  
  ngOnInit(): void {
    this.retrieveDataFromSession();
    this.fillForm();
  }

  private createMockup(): void {
    this.dynamicStorageService.removeKeyValueList(this.RAILORDER_COMMERCIAL_SPECIFICATION_LISTNAME);
    const dataList: KeyValuePair[] = [];
    const item: KeyValuePair = {
      key: '0000',
      value: 'Auswahl'
    }
    dataList.push(item);
    for (let i = 1; i < 5; i++) {
      const item: KeyValuePair = {
        key: '000' + i,
        value: '000' + i,
      }
      dataList.push(item);
    }
    this.dynamicStorageService.storeKeyValueList(this.RAILORDER_COMMERCIAL_SPECIFICATION_LISTNAME, dataList);
  }

  private retrieveDataFromSession(): void {
    this.dynamicStorageService.retrieveKeyValueList(this.RAILORDER_COMMERCIAL_SPECIFICATION_LISTNAME).subscribe((dataList: KeyValuePair[]) => {
    for (let item of dataList) {
      const commercialSpecification: CommercialSpecification = {
        code: item.key,
        description: item.value
      }
      this.commercialSpecifications.push(commercialSpecification);
    }
    });

    this.dynamicStorageService.retrieveKeyValueList(RAILORDER_COMMERCIAL_CURRENCY_LISTNAME)
      .subscribe({
        next: data => this.currencyList = data || [],
        error: err => console.error(`Failed to load authority list: ${err}`)
      });
  }

  private fillForm(): void {
    const item: KeyValuePair = {
      key: '0000',
      value: 'Auswahl'
    }
    const commercialSpecification: CommercialSpecification = {
      code: item.key,
      description: item.value
    }
    this.addCommercialSpecification(commercialSpecification);
    /*for (let control of this.commercialSpecification.controls) {
      control.setValue(item);
    }*/
      
  }

  private createProductExtraChargeCodes () {
    const productExtraChargeCodes = this.formGroup.get('productExtraChargeCodes') as FormArray;
    for (let i = 0; i < 5; i++) {
      const itemGroup: FormGroup = this.formBuilder.group({
        productExtraChargeCode: new FormControl('')
      });
      productExtraChargeCodes.push(itemGroup);
  }
}
  private createForm(): void {
    this.formGroup = new FormGroup({
      prepaymentNote: new FormControl(''),
      productExtraChargeCodes: new FormArray([]),
      prepaymentUpToAuthority: new FormControl(''),
      prepaymentUpToBorderDescription: new FormControl(''),
      commercialSpecification: this.formBuilder.array([]),
      loadingAuthorisation: new FormControl(''),
      commercialInformationNhmCode: new FormControl(''),
      commercialInformationContractNumber: new FormControl(''),
      commercialInformationDiscountCode: new FormControl(''),
      commercialInformationValueOfCommodityPrice: new FormControl(''),
      commercialInformationValueOfCommodityCurrency: new FormControl(''),
      commercialInformationValueOfDeliveryPrice: new FormControl(''),
      commercialInformationValueOfDeliveryCurrency: new FormControl(''),
      consignorDescriptionTextarea: new FormControl(''),
      commercialInformationExternalReferenceIdentifier: new FormControl(''),
      commercialInformationExternalReferenceConsigmentNumber: new FormControl(''),
      executingCarrierRuCode: new FormControl(''),
      sectionalInvoicingCarrierCode: new FormControl('')
    });    
    this.createProductExtraChargeCodes();
  }

  protected deleteItem(idx: number): void {
    if (this.commercialSpecification.length > 1) {
      this.commercialSpecification.removeAt(idx);
    }
  }


  protected addProdcutExtraChargeCode(item?:number) : void {
    const itemGroup: FormGroup = this.formBuilder.group({});
    if (item) {
      itemGroup.addControl('productExtraChargeCode', new FormControl(item));
    } else {
      itemGroup.addControl('productExtraChargeCode', new FormControl());
    }
  }

  protected addCommercialSpecification(item?: CommercialSpecification): void {
    const itemGroup: FormGroup = this.formBuilder.group({});
    if (item) {
      itemGroup.addControl('commercialSpecificationAdditionalInfo', new FormControl(item.additionalInformation));
      itemGroup.addControl('commercialSpecificationCode', new FormControl(item.code));
      itemGroup.addControl('commercialSpecificationDescription', new FormControl(item.description));
    } else {
      itemGroup.addControl('commercialSpecificationAdditionalInfo', new FormControl());
      itemGroup.addControl('commercialSpecificationCode', new FormControl());
      itemGroup.addControl('commercialSpecificationDescription', new FormControl());
    }
    this.commercialSpecification.push(itemGroup);
  }

  public validate(): string[] {
    console.log('validate consignor-consignee section');
    return [];
  }

  protected toggleSectionalInvoicing(): void {
    this.isSectionalInvoicing = !this.isSectionalInvoicing;
  }

  protected toggleAdditionalPrepaymentInput(): void {
    this.isAdditionalPrepaymentInput = !this.isAdditionalPrepaymentInput;
  }

  protected toggleCommercialSpecification(): void {
    this.isCommercialSpecification = !this.isCommercialSpecification;
  }

  protected toggleAdditionalCommercialInformation(): void {
    this.isAdditionalCommercialInformation = !this.isAdditionalCommercialInformation;
  }

  protected toggleAnnotationOfConsignorDescription(): void {
    this.isAnnotationOfConsignorDescription = !this.isAnnotationOfConsignorDescription;
  }

  protected toggleDbInternal(): void {
    this.isDbInternal = !this.isDbInternal;
  }

  protected get prepaymentNote(): FormControl {
    return this.formGroup.get('prepaymentNote') as FormControl;
  }

  protected get productExtraChargeCodes(): FormArray {
    return this.formGroup.get('productExtraChargeCodes') as FormArray;
  }

  protected get prepaymentUpToAuthority(): FormControl {
    return this.formGroup.get('prepaymentUpToAuthority') as FormControl;
  }

  protected get prepaymentUpToBorderDescription(): FormControl {
    return this.formGroup.get('prepaymentUpToBorderDescription') as FormControl;
  }

  protected get commercialSpecification(): FormArray {
    return this.formGroup.get('commercialSpecification') as FormArray;
  }

  protected get loadingAuthorisation(): FormControl {
    return this.formGroup.get('loadingAuthorisation') as FormControl;
  }
  
  protected get commercialInformationNhmCode(): FormControl {
    return this.formGroup.get('commercialInformationNhmCode') as FormControl;
  }

  protected get commercialInformationContractNumber(): FormControl {
    return this.formGroup.get('commercialInformationContractNumber') as FormControl;
  }

  protected get commercialInformationDiscountCode(): FormControl {
    return this.formGroup.get('commercialInformationDiscountCode') as FormControl;
  }

  protected get commercialInformationValueOfCommodityPrice(): FormControl {
    return this.formGroup.get('commercialInformationValueOfCommodityPrice') as FormControl;
  }

  protected get commercialInformationValueOfDeliveryPrice(): FormControl {
    return this.formGroup.get('commercialInformationValueOfDeliveryPrice') as FormControl;
  }  

  protected get commercialInformationExternalReferenceIdentifier(): FormControl {
    return this.formGroup.get('commercialInformationExternalReferenceIdentifier') as FormControl;
  }

  protected get commercialInformationExternalReferenceConsigmentNumber(): FormControl {
    return this.formGroup.get('commercialInformationExternalReferenceConsigmentNumber') as FormControl;
  }

  protected get executingCarrierRuCode(): FormControl {
    return this.formGroup.get('executingCarrierRuCode') as FormControl;
  }

  protected get sectionalInvoicingCarrierCode(): FormControl {
    return this.formGroup.get('sectionalInvoicingCarrierCode') as FormControl;
  }
}