


























































































































































































































































































































































































































import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CustomerSelectModule } from 'src/app/shared/components/customer-select/cusomter-select.module';
import { SideNavigationModule } from '../side-navigation/side-navigation.module';
import { LocaleModule } from '../locale/locale.module';

@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    CustomerSelectModule,
    LocaleModule,
    SideNavigationModule,
    RouterModule
  ],
  exports: [
    HeaderComponent
  ]        
})
export class HeaderModule {

}