import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NewOrderPickupDeliveryComponent } from './new-order-pickup-delivery.component';

@NgModule({
  declarations: [
    NewOrderPickupDeliveryComponent
  ],
  imports: [  
    SharedModule,
    MatDialogModule    
  ],
  exports: [
    NewOrderPickupDeliveryComponent
  ]

})
export class NewOrderPickupDeliveryModule { }