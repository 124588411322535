import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Goods } from "../../../../models/api-railorder";

@Component({
  selector: 'app-goods-information-list',
  templateUrl: './goods-information-list.component.html',
  styleUrls: ['./goods-information-list.component.scss']
})
export class GoodsInformationListComponent implements OnInit {

  @Input() formGroup: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    if (this.goodsInformationList.length === 0) {
      this.addNewLine();
    }
  }

  protected addNewLine(item?: Goods): void {
    const itemGroup: FormGroup = this.fb.group({});
    if (item) {
      itemGroup.addControl('test_1', new FormControl(''));
      itemGroup.addControl('test_2', new FormControl(''));
      itemGroup.addControl('test_3', new FormControl(''));
      itemGroup.addControl('test_4', new FormControl(''));
      itemGroup.addControl('test_5', new FormControl(''));
      itemGroup.addControl('test_6', new FormControl(''));
      itemGroup.addControl('test_7', new FormControl(''));
      itemGroup.addControl('test_8', new FormControl(''));
      itemGroup.addControl('test_9', new FormControl(''));
      itemGroup.addControl('test_10', new FormControl(''));
      itemGroup.addControl('test_11', new FormControl(''));
      itemGroup.addControl('test_12', new FormControl(''));
    } else {
      itemGroup.addControl('test_1', new FormControl());
      itemGroup.addControl('test_2', new FormControl());
      itemGroup.addControl('test_3', new FormControl());
      itemGroup.addControl('test_4', new FormControl());
      itemGroup.addControl('test_5', new FormControl());
      itemGroup.addControl('test_6', new FormControl());
      itemGroup.addControl('test_7', new FormControl());
      itemGroup.addControl('test_8', new FormControl());
      itemGroup.addControl('test_9', new FormControl());
      itemGroup.addControl('test_10', new FormControl());
      itemGroup.addControl('test_11', new FormControl());
      itemGroup.addControl('test_12', new FormControl());
    }
    this.goodsInformationList.push(itemGroup);    
  }

  protected removeLine(idx: number): void {
    if (this.goodsInformationList.length > 1) {
      this.goodsInformationList.removeAt(idx);
    }   
  }

  protected get goodsInformationList(): FormArray {
    return this.formGroup?.get('goodsInformationList') as FormArray;
  }
}