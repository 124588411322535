import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingTacklesListComponent } from './loading-tackles-list.component';

@NgModule({
  declarations: [
    LoadingTacklesListComponent
  ],
  imports: [
    ReactiveFormsModule, 
    SharedModule
  ],
  exports: [
    LoadingTacklesListComponent
  ]
})
export class LoadingTacklesListModule { }