import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DangerousGoodsComponent } from './dangerous-goods.component';

@NgModule({
  declarations: [
    DangerousGoodsComponent
  ],
  imports: [
    ReactiveFormsModule, 
    SharedModule
  ],
  exports: [
    DangerousGoodsComponent
  ]
})
export class DangerousGoodsModule { }