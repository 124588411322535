import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { WagonInformationListComponent } from './wagon-information-list.component';

@NgModule({
  declarations: [
    WagonInformationListComponent
  ],
  imports: [
    ReactiveFormsModule, 
    SharedModule
  ],
  exports: [
    WagonInformationListComponent
  ]
})
export class WagonInformationListModule { }