import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from 'src/app/shared/enums/api-urls.enum';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Authorization, AuthorizationMapping, AuthorizationResponse, CustomerData } from 'src/app/trainorder/models/authorization';
import { EnvService } from '../services/env/env.service';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { ModalWindows } from '../components/modal-windows/modal-windows';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    backendUrl: string;
    private immediateAuthorizations: Authorization[];
    private customerProfiles: CustomerData[];
    private activeProfiles: CustomerData[];
    loadingInitiated: boolean = false;
    permissionsInitialized: boolean = false;

    constructor(
        private httpClient: HttpClient,
        private env: EnvService,
        public router: Router,
        private modalWindows: ModalWindows,
        private storageService: LocalStorageService
    ) {
        this.backendUrl = this.env.backendUrl;
        if (typeof this.backendUrl == 'undefined' || this.backendUrl == null) {
            console.info("no environment setting for backendUrl found!");
        }
    }

    /**
     * Usage example without routerUrl in *.ts:
     * 
     * Constructor:
     * constructor(..., permissionService: PermissionService, ...) {
     * ...
     * }
     * 
     * in methods:
     * ...
     * this.permissionService.hasPermission(null, [Authorization.READ_TRACKING], authorizationsOnElement).subscribe(isAllowed => {
     *      if(isAllowed) {
     *          CODE TO EXECUTE IF PERMISSION IS GRANTED
     *      } else {
     *          CODE TO EXECUTE IF PERMISSION IS NOT GRANTED
     *      }
     * });
     * 
     * @param routeUrl 
     * @param requiredAuthorizations 
     * @param dynamicAuthorizations 
     * @param url 
     * @returns Observable<boolean>
     */
    public hasPermission(routeUrl: string | null, requiredAuthorizations?: Authorization[], dynamicAuthorizations?: Authorization[], url?: string): Observable<boolean> {
        let currentPermissions = this.getCurrentPermissions();
        if (currentPermissions != null) {
            const granted = this.getPermission(routeUrl, requiredAuthorizations, dynamicAuthorizations, url);
            return of(granted);
        }

        return new Observable<boolean>(observer => {
            this.storageService.watchPermissions().subscribe((_) => {
                const granted = this.getPermission(routeUrl, requiredAuthorizations, dynamicAuthorizations, url);
                observer.next(granted);
                observer.complete();
            });
        });
    }

    public loadPermissions4User(): Observable<AuthorizationResponse> {
        if (!this.loadingInitiated && !this.permissionsInitialized) {
            this.loadingInitiated = true;
            const perm$ = this.requestPermissions();
            perm$.subscribe((authResponse: AuthorizationResponse) => {

                this.immediateAuthorizations = [];
                this.customerProfiles = [];

                authResponse?.authorization.forEach(a => {
                    // console.log("a", a);
                    this.immediateAuthorizations.push(a);
                });

                authResponse?.profiles.forEach(p => {
                    this.customerProfiles.push(p);
                });

                if (this.customerProfiles != null) {
                    this.storageService.setCustomerProfiles(this.customerProfiles);
                }
                this.loadingInitiated = false;
                this.permissionsInitialized = true;

                // initially set first found profile as active profile
                if (this.customerProfiles != null && this.customerProfiles.length > 0) {
                    this.storageService.setActiveProfiles([this.customerProfiles[0]]);
                }

                this.storageService.setImmediateAuthorizations(this.immediateAuthorizations, true);
            });
            return perm$;
        }
        return of();
    }

    public requestPermissions() {
        return this.httpClient.get<AuthorizationResponse>(this.backendUrl + ApiUrls.AUTHORIZATION);
        // const authResponse = PermissionsMock.getDummyAuthResponse();
        // return of(authResponse);
    }

    private getPermission(routeUrl: string | null, requiredAuthorizations?: Authorization[], dynamicAuthorizations?: Authorization[], url?: string): boolean {
        let requiredAuthFunctions: Authorization[] = [];
        if (routeUrl) {
            requiredAuthFunctions = AuthorizationMapping.mapFEUrl2AuthorizationFunction(routeUrl);
        } else if (requiredAuthorizations) {
            requiredAuthFunctions = requiredAuthorizations;
        } else {
            return true;
        }
        const hasPermission = requiredAuthFunctions.some(el => this.getCurrentPermissions()?.includes(el));

        const hasDynamicPermission = requiredAuthFunctions.some(el => dynamicAuthorizations?.includes(el));
        // console.log("permission granted", hasPermission, hasDynamicPermission);
        return hasPermission || hasDynamicPermission;
    }

    private getCurrentPermissions(): Authorization[] | null {

        let currentPermissions: Authorization[] = [];
        if (!this.immediateAuthorizations) {
            const ia = this.storageService.getImmediateAuthorizations();

            if (ia == null) return ia;

            this.immediateAuthorizations = ia;
            // console.log("read immediateAuthorizations from local storage", this.immediateAuthorizations);
        }

        currentPermissions.push(...this.immediateAuthorizations);

        if (!this.customerProfiles) {
            const cp = this.storageService.getCustomerProfiles();
            if (cp != null) {
                this.customerProfiles = cp;
                // console.log("read customerProfiles from local storage", this.customerProfiles);
            }
        }

        const ap = this.storageService.getActiveProfiles();
        this.activeProfiles = ap && ap != null ? ap : [];
        this.activeProfiles.forEach(profile => currentPermissions.push(...profile.authorization))

        const authorizations = [...new Set(currentPermissions)];
        // console.log("currentAuthorizations", authorizations)
        return authorizations;
    }

    showDeniedError() {
        this.modalWindows.openErrorDialog({ text: "Permission denied" });
        setTimeout(() => {
            this.modalWindows.closeAllModalWindows();
            this.router.navigate(['gzp', 'trainorder', 'home']);
        }, 2000);
    }

    public hasDynamicPermission(dynamicAuthorizations: Authorization[], requiredAuthorizations: Authorization[]): boolean {
        return requiredAuthorizations.some(el => dynamicAuthorizations.includes(el));
    }
}