import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SectionName } from '../../enums/order-enums';
import { RailOrder } from '../../models/api-railorder';
import { NewOrderMainComponent } from '../../new-order-main/new-order-main.component';
import { SectionBase } from '../section.base';
import { FormControl, FormGroup } from '@angular/forms';
import { RAILORDER_COUNTRY_LISTNAME, RailorderCountry } from '../../models/api-dynamic-storage';
import { DynamicStorageService } from '../../service/dynamic-session-storage.service';

@Component({
  selector: 'app-new-order-pickup-delivery',
  templateUrl: './new-order-pickup-delivery.component.html',
  styleUrls: ['../../new-order-main/new-order-main.component.scss',
    './new-order-pickup-delivery.component.scss']
})
export class NewOrderPickupDeliveryComponent extends SectionBase implements OnChanges {

  @Input() currentSectionName: SectionName;
  @Input() railOrder: RailOrder;

  protected SectionName = SectionName;
  protected parent: NewOrderMainComponent;
  protected formGroup: FormGroup;
  protected isPickupLocationVisible: boolean;
  protected isDeliveryLocationVisible: boolean;
  protected railOrderCountryList:RailorderCountry[] =[] ;
  

  constructor(private injector: Injector, private dynamicStorageService: DynamicStorageService ) {
    super();
    this.loadLists();
    this.parent = this.injector.get<NewOrderMainComponent>(NewOrderMainComponent);
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const railOrderChanges = changes['railOrder'];
    if (railOrderChanges) {
      const railOrder: RailOrder = railOrderChanges.currentValue;
      if (railOrder) {
        this.setFormValues();
      }
    }
  }

  public togglePickupLocation(): void {
    this.isPickupLocationVisible = !this.isPickupLocationVisible;
  }

  public toggleDeliveryLocation(): void {
    this.isDeliveryLocationVisible = !this.isDeliveryLocationVisible;
  }

  private setFormValues(): void {
    this.setFormValuesPickup();
    this.setFormValuesDelivery();

  }

  private setFormValuesPickup(): void {
    this.deliveryLocationInfo.setValue(this.railOrder?.deliveryPoint?.information);
    this.deliveryLocationCode.setValue(this.railOrder?.operationalTransportConditions.deliveryPointProductionNode.locationCode);
    this.deliveryLocationText.setValue(this.railOrder?.operationalTransportConditions.deliveryPointProductionNode.locationName);
    this.deliveryCountry.setValue(this.railOrder?.deliveryPoint.authority);
    this.deliveryStation.setValue(this.railOrder?.deliveryPoint.locationCode);
    this.deliverySealoadingpoint.setValue(this.railOrder?.deliveryPoint?.commercialLocation?.locationCode);
  }

  private setFormValuesDelivery(): void {
    this.pickupLocationInfo.setValue(this.railOrder?.acceptancePoint?.information);
    this.pickupLocationCode.setValue(this.railOrder?.operationalTransportConditions.acceptancePointProductionNode.locationCode);
    this.pickupLocationText.setValue(this.railOrder?.operationalTransportConditions.acceptancePointProductionNode.locationName);
    this.pickupCountry.setValue(this.railOrder?.acceptancePoint.authority);
    this.pickupStation.setValue(this.railOrder?.acceptancePoint.locationCode);
    this.pickupSealoadingpoint.setValue(this.railOrder?.acceptancePoint?.commercialLocation?.locationCode);
  }

  private createForm(): void {
    this.formGroup = new FormGroup({
      pickupCountry: new FormControl(''),
      pickupStation: new FormControl(''),
      pickupSealoadingpoint: new FormControl(''),
      pickupLocationInfo: new FormControl(''),
      pickupLocationCode: new FormControl(''),
      pickupLocationText: new FormControl(''),
      shippingDate: new FormControl(''),
      shippingTime: new FormControl(''),
      deliveryLocationInfo: new FormControl(''),
      deliveryLocationCode: new FormControl(''),
      deliveryLocationText: new FormControl(''),
      deliveryCountry: new FormControl(''),
      deliveryStation: new FormControl(''),
      deliverySealoadingpoint: new FormControl(''),
    });
  }
 
  private loadLists(): void {
      this.dynamicStorageService.retrieveConfigurationList(RAILORDER_COUNTRY_LISTNAME)
      .subscribe({
        next: data => this.railOrderCountryList = data || [],
        error: err => console.error(`Failed to load country list: ${err}`)
      });
  }

  public validate(): string[] {
    return [];
  }

  public get deliveryCountry(): FormControl {
    return this.formGroup.get('deliveryCountry') as FormControl;
  }

  public get deliveryLocationInfo(): FormControl {
    return this.formGroup.get('deliveryLocationInfo') as FormControl;
  }

  public get deliveryLocationCode(): FormControl {
    return this.formGroup.get('deliveryLocationCode') as FormControl;
  }

  public get deliveryLocationText(): FormControl {
    return this.formGroup.get('deliveryLocationText') as FormControl;
  }

  public get deliveryStation(): FormControl {
    return this.formGroup.get('deliveryStation') as FormControl;
  }

  public get deliverySealoadingpoint(): FormControl {
    return this.formGroup.get('deliverySealoadingpoint') as FormControl;
  }

  public get szv(): FormControl {
    return this.formGroup.get('bzv') as FormControl;
  }

  public get pickupCountry(): FormControl {
    return this.formGroup.get('pickupCountry') as FormControl;
  }

  public get pickupLocationInfo(): FormControl {
    return this.formGroup.get('pickupLocationInfo') as FormControl;
  }

  public get pickupLocationCode(): FormControl {
    return this.formGroup.get('pickupLocationCode') as FormControl;
  }

  public get shippingDate(): FormControl {
    return this.formGroup.get('shippingDate') as FormControl;
  }

  public get shippingTime(): FormControl {
    return this.formGroup.get('shippingDate') as FormControl;
  }


  public get pickupLocationText(): FormControl {
    return this.formGroup.get('pickupLocationText') as FormControl;
  }

  public get pickupStation(): FormControl {
    return this.formGroup.get('pickupStation') as FormControl;
  }

  public get pickupSealoadingpoint(): FormControl {
    return this.formGroup.get('pickupSealoadingpoint') as FormControl;
  }
  onChangeDeliveryCounty($event: Event) {
    throw new Error('Method not implemented.');
    }
    onChangePickupCountry($event: Event) {
    throw new Error('Method not implemented.');
    }
}