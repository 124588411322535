import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SectionName } from '../../enums/order-enums';
import { RailOrder } from '../../models/api-railorder';
import { NewOrderMainComponent } from '../../new-order-main/new-order-main.component';
import { SectionBase } from '../section.base';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-new-order-wagon-data',
  templateUrl: './new-order-wagon-data.component.html',
  styleUrls: ['../../new-order-main/new-order-main.component.scss', 
    './new-order-wagon-data.component.scss']
})
export class NewOrderWagonDataComponent extends SectionBase implements OnChanges {
  @Input() currentSectionName: SectionName;
  @Input() railOrder: RailOrder;

  protected formGroup: FormGroup;
  protected SectionName = SectionName;
  protected parent: NewOrderMainComponent;
  protected numbersOfWagonsList: number[] = [];
  protected isAdditionalInput: boolean;
  protected isDangerousGoods: boolean;
  protected isCustomerReference: boolean;
  protected isSealingList: boolean;
  protected isLoadingTacklesList: boolean;
  protected isAuthorizationList: boolean;

  constructor(private injector: Injector, private fb: FormBuilder) {
    super();
    this.parent = this.injector.get<NewOrderMainComponent>(NewOrderMainComponent);
    this.loadLists();
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const railOrderChanges = changes['railOrder'];
    if (railOrderChanges) {
      const railOrder: RailOrder = railOrderChanges.currentValue;
      if (railOrder) {
        this.setFormValues();
      }
    }
  }

  protected toggleAuthorization(): void {
    this.isAuthorizationList = !this.isAuthorizationList;
  }
  
  protected toggleAdditionalInput(): void {
    this.isAdditionalInput = !this.isAdditionalInput;
  }

  protected toggleDangerousGoods(): void {
    this.isDangerousGoods = !this.isDangerousGoods;
  }

  protected toggleCustomerReference(): void {
    this.isCustomerReference = !this.isCustomerReference;
  }

  protected toggleSealingList(): void {
    this.isSealingList = !this.isSealingList;
  }

  protected toggleLoadingTackles(): void {
    this.isLoadingTacklesList = !this.isLoadingTacklesList
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      numberOfWagons: [''],
      wagonInformationList: this.fb.array([]),
      goodsInformationList: this.fb.array([]),
      customerReferenceList: this.fb.array([]),
      sealingList: this.fb.array([]),
      loadingTacklesList: this.fb.array([]),
      authorizationList: this.fb.array([])
    });
  }

  private setFormValues(): void {
    
  }

  protected get numberOfWagons(): FormControl {
    return this.formGroup.get('numberOfWagons') as FormControl;
  }
  //#endregion
  
  private loadLists() {
    for (let i = 1; i <= 99; i++) {
      this.numbersOfWagonsList.push(i);
    }
  }
  
  protected validate(): string[] {
    console.log('validate Wagon information section');
    return [];
  }
}
