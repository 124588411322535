import { Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { LoadingTackles } from "../../../../models/api-railorder";

@Component({
  selector: 'app-loading-tackles-list',
  templateUrl: './loading-tackles-list.component.html',
  styleUrls: ['./loading-tackles-list.component.scss']
})
export class LoadingTacklesListComponent {

  @Input() formGroup: FormGroup;

  constructor(private fb: FormBuilder) {

  }

  ngOnInit(): void {
    if (this.loadingTacklesList.length === 0) {
      this.addNewLine();
    }
  }

  protected get loadingTacklesList(): FormArray {
    return this.formGroup?.get('loadingTacklesList') as FormArray;
  }

  protected addNewLine(item?: LoadingTackles): void {
    const itemGroup: FormGroup = this.fb.group({});
    if (item) {
      itemGroup.addControl('test_1', new FormControl(''));      
    } else {
      itemGroup.addControl('test_1', new FormControl());      
    }
    this.loadingTacklesList.push(itemGroup);    
  }

  protected removeLine(idx: number): void {
    if (this.loadingTacklesList.length > 1) {
      this.loadingTacklesList.removeAt(idx);
    }   
  }
}