import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { CustomerProfile } from './trainorder/models/authorization';
import { AppService } from './app.service';
import { HeaderComponent } from './shared/components/header/header.component';
import { LocalStorageService } from './shared/services/local-storage/local-storage.service';
import { AuthService } from './shared/services/auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

    @ViewChild('header', { static: false }) header!: HeaderComponent;
     
    protected title = 'Train Management';
    protected permissionsLoaded: boolean = false;

    constructor(private storageService: LocalStorageService, private authService: AuthService, private appService: AppService) {

    }
    
    ngOnInit(): void {
        if(this.storageService.getUsername() == null) {
            this.storageService.watchPermissions().subscribe((_) => {
                this.permissionsLoaded = true;                
                console.log('permissionLoaded: ' + this.permissionsLoaded);
            });
        } else {            
            this.permissionsLoaded = true;
        }

        if(this.authService.hasAccessToken()) {
            this.authService.requestToken();
        }
        console.log('permissionLoaded: ' + this.permissionsLoaded);
    }

    ngAfterViewInit(): void {
        // this.header.customerSelection.customerSelected.subscribe({
        //     next: ( (customerProfile: CustomerProfile) => {
        //       this.appService.customerSelection.next(customerProfile);
        //     })
        //   });
    }    
}
