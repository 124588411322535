import { Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";

interface Authorization {
  attr_1: string,
  attr_2: string
}

@Component({
  selector: 'app-authorization-list',
  templateUrl: './authorization-list.component.html',
  styleUrls: ['./authorization-list.component.scss']
})
export class AuthorizationListComponent {

  @Input() formGroup: FormGroup;

  constructor(private fb: FormBuilder) {

  }

  ngOnInit(): void {
    if (this.authorizationList.length === 0) {
      this.addNewLine();
    }
  }

  protected get authorizationList(): FormArray {
    return this.formGroup?.get('authorizationList') as FormArray;
  }

  protected addNewLine(item?: Authorization): void {
    const itemGroup: FormGroup = this.fb.group({});
    if (item) {
      itemGroup.addControl('test_1', new FormControl(''));      
    } else {
      itemGroup.addControl('test_1', new FormControl());      
    }
    this.authorizationList.push(itemGroup);    
  }

  protected removeLine(idx: number): void {
    if (this.authorizationList.length > 1) {
      this.authorizationList.removeAt(idx);
    }   
  }
}