// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  height: 100%;
}

header {
  height: 190px;
}

body {
  max-height: 1200;
}

.currentDate {
  min-width: 135px;
}

.table-header {
  position: sticky;
  bottom: 0;
  background-color: #fff;
}

.history-control-block {
  padding: 15px;
}
.history-control-block h4 {
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}
.history-control-block input {
  margin-right: 5px;
}
.history-control-block .selected-option {
  font-weight: bold;
}

.history-table-block {
  padding: 0px 2px 15px 15px;
}

.cmp-table {
  width: 100%;
}
.cmp-table th {
  padding-top: 23px;
  padding-bottom: 23px;
  background: #fff;
}
.cmp-table tr:nth-child(odd) {
  background-color: #eef1f3;
}
.cmp-table tbody td {
  text-align: left;
  vertical-align: middle;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: unset;
}
.cmp-table tbody tr:first-of-type td:first-of-type .first-event {
  color: #000;
}
.cmp-table tbody tr td:first-of-type img {
  width: 25px;
  height: 25px;
}
.cmp-table tbody tr td:first-of-type .icon {
  position: relative;
}
.cmp-table tbody tr td:first-of-type .first-event {
  color: #646973;
}
.cmp-table tbody tr td:first-of-type .icon:not(.last-event):after {
  position: absolute;
  content: "";
  width: 2px;
  height: 20px;
  background: #6B707A;
  left: 11px;
  bottom: -23px;
}
.cmp-table tbody tr:last-of-type td:first-of-type .icon::after {
  content: none;
}
.cmp-table tbody tr:last-of-type td:first-of-type .first-event {
  color: #000;
}
.cmp-table .flex-td {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.modal-main {
  max-height: calc(100vh - 294px);
}

.modal-main-trackinghistory {
  padding: 0px 5px 0px 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
