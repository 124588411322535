import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import '@angular/common/locales/global/de';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ErrorComponent } from './shared/error/error.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TrainIdPipe } from './trainorder/pipes/train-id.pipe';
import { TrainTypePipe } from './trainorder/pipes/train-type.pipe';
import { OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GlobalErrorHandler } from './shared/error-handler/error-handler';
import { HeaderModule } from './shared/components/header/header.module';
import { FooterModule } from './shared/components/footer/footer.module';
import { LanguageInterceptor } from './shared/interceptors/language.interceptor';
import { EnvServiceFactory, EnvServiceProvider } from './shared/services/env/env.service.provider';
import { ModalWindows } from './shared/components/modal-windows/modal-windows';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { SystemInformationModule } from './system-information/system-information.module';
import { LoggingInterceptor } from './shared/interceptors/logging.interceptor';

const envService = EnvServiceFactory();

export function storageFactory() : OAuthStorage {
  return localStorage
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http); 
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HeaderModule,
    FooterModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SystemInformationModule,
    NgMultiSelectDropDownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [envService.backendUrlTnt, envService.backendUrl, envService.backendUrlUserManagement, envService.backendUrlOm],
        sendAccessToken: true
      }
    })
  ],
  providers: [
    TrainIdPipe,
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: OAuthStorage, useFactory: storageFactory },    
    EnvServiceProvider,
    TrainTypePipe,
    ModalWindows,
    AuthGuard,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
