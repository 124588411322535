import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DocumentationService } from "./documentation.service";
import { TranslateService } from '@ngx-translate/core';
import { ModalWindows } from "../../modal-windows/modal-windows";
import { UserManual } from "src/app/system-information/models/user-manual.model";
import { ReleaseInformation } from "src/app/system-information/models/release-Information.model";
import { ReleaseInformationResponse } from "src/app/system-information/models/release-information-response.model";
import { UserManualResponse } from "src/app/system-information/models/user-manual-response.model";
@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss'],
})
export class DocumentationComponent implements OnInit {
  
  protected rawManual: UserManual;
  protected rawRelease: ReleaseInformation;
  protected showManual: boolean;
  protected manual: Uint8Array;
  protected userManualFileName: string;
  protected releaseNotesFileName: string;
  protected showNotes: boolean;
  protected notes: Uint8Array;
  protected loadingInProgress: boolean;

  constructor(private dialogRef: MatDialogRef<DocumentationComponent>, private documentationService: DocumentationService, private modalWindows: ModalWindows, private translate: TranslateService) {
    
  }

  ngOnInit(): void {
    this.fetchLatestUserManual();
    this.fetchLatestReleaseNotes();
  }

  private fetchLatestReleaseNotes(): void {
    this.documentationService.fetchLatestReleaseNotes().subscribe({
      next: (result: ReleaseInformationResponse) => {
        if (result) {
          if (result && result.releaseInformationList && result.releaseInformationList[0] && result.releaseInformationList[0].pdf) {
            this.rawRelease = result.releaseInformationList[0];
            this.notes = this.convertDataURIToBinary(result.releaseInformationList[0].pdf);
            this.releaseNotesFileName = result.releaseInformationList[0].name + " " + result.releaseInformationList[0].version;
            this.showNotes = true;            
          } else {
            this.handleError(this.translate.instant('Shared.Errors.fetch-release-notes'));
            console.error('Failed to fetch release notes: No documents available on server side');
          }          
        }
      },
      error: (error) => {
        this.handleError(this.translate.instant('Shared.Errors.fetch-release-notes'));
        console.error(error);
      }
    }).add(() => {
      this.loadingInProgress = false;            
    });
  }

  private fetchLatestUserManual(): void {
    this.documentationService.fetchLatestUserManual().subscribe({
      next: (result: UserManualResponse) => {
        if (result) {
          if (result && result.usermanuals && result.usermanuals[0] && result.usermanuals[0].pdf) {
            this.rawManual = result.usermanuals[0];
            this.manual = this.convertDataURIToBinary(result.usermanuals[0].pdf);
            this.userManualFileName = result.usermanuals[0].name + " " + result.usermanuals[0].version;
            this.showManual = true;
          } else {
            this.handleError(this.translate.instant('Shared.Errors.fetch-user-manual'));
            console.error('Failed to fetch user manual: No documents available on server side');
          }
        }
      },
      error: (error) => {
        this.handleError(this.translate.instant('Shared.Errors.fetch-user-manual'));
        console.error(error);
      }
    }).add(() => {
      this.loadingInProgress = false;            
    });
  }

  protected downloadUserManual() {
    const linkSource = 'data:application/pdf;base64,' + this.rawManual.pdf;
    const downloadLink = document.createElement("a");
    const fileName = this.userManualFileName + '.pdf';
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  protected downloadReleaseNotes() {
    const linkSource = 'data:application/pdf;base64,' + this.rawRelease.pdf;
    const downloadLink = document.createElement("a");
    const fileName = this.releaseNotesFileName + '.pdf';
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  private handleError(message: string): void {
    this.modalWindows.openErrorDialog({ text: message });
  }

  close(): void {
    this.dialogRef.close();
  }

  protected releaseInfoSelected(): void {

  }

  private convertDataURIToBinary(dataURI: string): Uint8Array {
    var raw = window.atob(dataURI);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for(var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i); 
    }
    return array;
  }
}