import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-error',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './generic-error.component.html',
  styleUrl: './generic-error.component.scss'
})
export class GenericErrorComponent {
  protected properties;
  constructor(@Inject(MAT_DIALOG_DATA) public error: any) {
    console.log(error.length);
    this.prepareProperties();
  }

  private prepareProperties() {
    this.properties = Object.entries(this.error);
  }
}
