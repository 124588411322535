import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PartyType, SectionName } from '../../enums/order-enums';
import { RailOrder } from '../../models/api-railorder';
import { NewOrderMainComponent } from '../../new-order-main/new-order-main.component';
import { SectionBase } from "../section.base";
import { FormControl, FormGroup } from '@angular/forms';
import { DynamicStorageService } from '../../service/dynamic-session-storage.service';
import { RAILORDER_AUTHORITYLIST_LISTNAME, RAILORDER_COUNTRY_LISTNAME, RailorderCountry } from '../../models/api-dynamic-storage';

@Component({
  selector: 'app-new-order-consignor-consignee',
  templateUrl: './new-order-consignor-consignee.component.html',
  styleUrls: ['../../new-order-main/new-order-main.component.scss',
    './new-order-consignor-consignee.component.scss']
})

export class NewOrderConsignorConsigneeComponent extends SectionBase implements OnChanges {
clearInput(arg0: string) {
throw new Error('Method not implemented.');
}
ordererSgvAutoComplete: any;
selectConsignorSgvId($event: Event) {
throw new Error('Method not implemented.');
}
getConsignorSgvIdsList($event: Event) {
throw new Error('Method not implemented.');
}
  @Input() currentSectionName: SectionName;
  @Input() railOrder: RailOrder;

  protected SectionName = SectionName;
  protected parent: NewOrderMainComponent;
  protected formGroup: FormGroup;
  protected enumPartyTypeConsignor: PartyType;
  protected enumPartyTypeConsignee: PartyType;
  protected enumPartTypeFreightPayerConsignor: PartyType.PARTY_TYPE_FREIGHT_PAYER_CONSIGNOR;
  protected enumPartTypeFreightPayerConsignee: PartyType.PARTY_TYPE_FREIGHT_PAYER_CONSIGNEE;
  protected isPartyInfoConsignorVisible = false; // To track visibility
  protected isPartyInfoConsigneeVisible = false; // To track visibility
  protected authorityList = [];
  protected railOrderCountryList:RailorderCountry[] =[] ;

  // Current section name
  protected currentLocalSectionName = this.SectionName.SECTION_CONSIGNEE_CONSIGNOR;
  constructor(private injector: Injector, private dynamicStorageService: DynamicStorageService) {
    super();
    this.loadLists();
    this.parent = this.injector.get<NewOrderMainComponent>(NewOrderMainComponent);
    this.enumPartyTypeConsignor = PartyType.PARTY_TYPE_CONSIGNOR;
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    const railOrderChanges = changes['railOrder'];
    if (railOrderChanges) {
      const railOrder: RailOrder = railOrderChanges.currentValue;
      if (railOrder) {
        console.log(railOrder);
        this.setFormValues(railOrder);
      }
    }
  }

  private loadLists(): void {
    this.dynamicStorageService.retrieveKeyValueList(RAILORDER_AUTHORITYLIST_LISTNAME)
      .subscribe({
        next: data => this.authorityList = data || [],
        error: err => console.error(`Failed to load authority list: ${err}`)
      });

      this.dynamicStorageService.retrieveConfigurationList(RAILORDER_COUNTRY_LISTNAME)
      .subscribe({
        next: data => this.railOrderCountryList = data || [],
        error: err => console.error(`Failed to load country list: ${err}`)
      });
  }

  private setFormValues(railOrder: RailOrder): void {
    this.setFormValuesConsignor(railOrder);
    this.setFormValuesFreightPayerConsignor(railOrder);
    this.setFormValuesLoadingParty(railOrder);

    this.setFormValuesConsignee(railOrder);
    this.setFormValuesFreightPayerConsignee(railOrder);
    this.setFormValuesUnloadingParty(railOrder)
  }

  private setFormValuesConsignor(railOrder: RailOrder): void {
    this.getConsignorCustomerID()?.setValue(railOrder?.consignor?.customerId?.sgv);
    this.getConsignorPartnerID()?.setValue(railOrder?.consignor?.partnerId.site);
    this.getConsignorAddressStreet()?.setValue(railOrder?.consignor?.address?.street);
    this.getConsignorAddressCity()?.setValue(railOrder?.consignor?.address?.city)
    this.getConsignorAddressCountry()?.setValue(railOrder?.consignor?.address?.country);
    this.getConsignorComment()?.setValue(railOrder?.consignor?.comment);
    this.getConsignorContactPersonName()?.setValue(railOrder?.consignor?.contactPerson.name);
    this.getConsignorContactPersonTel()?.setValue(railOrder?.consignor?.contactPerson.tel);
    this.getConsignorContactPersonFax()?.setValue(railOrder?.consignor?.contactPerson.fax);
    this.getConsignorContactPersonEmail()?.setValue(railOrder?.consignor?.contactPerson.email);
  }

  private setFormValuesFreightPayerConsignor(railOrder: RailOrder): void {
    this.getFreightPayerConsignorCustomerIdSgv()?.setValue(railOrder?.freightpayerConsignor?.customerId?.sgv);
    this.getFreightPayerConsignorCustomerIdAuthorityOfCustomerId()?.setValue(railOrder?.freightpayerConsignor?.customerId?.authorityOfCustomerId);
    this.getFreightPayerConsignorAddressZipCode()?.setValue(railOrder?.freightpayerConsignor?.address?.zipCode);
    this.getFreightPayerConsignorVatId()?.setValue(railOrder?.freightpayerConsignor?.vatId);
  }

  private setFormValuesFreightPayerConsignee(railOrder: RailOrder): void {
    this.getFreightPayerConsigneeCustomerIdSgv()?.setValue(railOrder?.freightpayerConsignor?.customerId?.sgv);
    this.getFreightPayerConsigeeCustomerIdAuthorityOfCustomerId()?.setValue(railOrder?.freightpayerConsignor?.customerId?.authorityOfCustomerId);
    this.getFreightPayerConsigneeAddressZipCode()?.setValue(railOrder?.freightpayerConsignor?.address?.zipCode);
    this.getFreightPayerConsigneeVatId()?.setValue(railOrder?.freightpayerConsignor?.vatId);
  }


  private setFormValuesLoadingParty(railOrder: RailOrder): void {
    this.getLoadingPartyCustomerIdSgv()?.setValue(railOrder?.loadingParty?.customerId?.sgv);
    this.getLoadingPartyCustomerIdAuthorityOfCustomerId()?.setValue(railOrder?.loadingParty?.customerId?.authorityOfCustomerId);
    this.getLoadingPartyAddressZipCode()?.setValue(railOrder?.loadingParty?.address?.zipCode);
  }

  private setFormValuesUnloadingParty(railOrder: RailOrder): void {
    this.getUnloadingPartyCustomerIdSgv()?.setValue(railOrder?.unloadingParty?.customerId?.sgv);
    this.getUnloadingPartyCustomerIdAuthorityOfCustomerId()?.setValue(railOrder?.unloadingParty?.customerId?.authorityOfCustomerId);
    this.getUnloadingPartyAddressZipCode()?.setValue(railOrder?.unloadingParty?.address?.zipCode);
  }

  private setFormValuesConsignee(railOrder: RailOrder): void {
    this.getConsigneeCustomerID()?.setValue(railOrder?.consignee?.customerId?.sgv);
    this.getConsigneePartnerID()?.setValue(railOrder?.consignee?.partnerId.site);
    this.getConsigneeName()?.setValue(railOrder?.consignee?.name);
    this.getConsigneeAddressStreet()?.setValue(railOrder?.consignee?.address?.street);
    this.getConsigneeAddressCity()?.setValue(railOrder?.consignee?.address?.city);
    this.getConsigneeAddressCountry()?.setValue(railOrder?.consignee?.address?.country);
    this.getConsigneeComment()?.setValue(railOrder?.consignee?.comment);
    this.getConsigneeContactPersonName()?.setValue(railOrder?.consignee?.contactPerson.name);
    this.getConsigneeContactPersonTel()?.setValue(railOrder?.consignee?.contactPerson.tel);
    this.getConsigneeContactPersonFax()?.setValue(railOrder?.consignor?.contactPerson.fax);
    this.getConsigneeContactPersonEmail()?.setValue(railOrder?.consignor?.contactPerson.email);


  }

  private createForm(): void {
    this.formGroup = new FormGroup({
      consignorCustomerID: new FormControl(''),
      consignorAddressCity: new FormControl(''),
      consignorPartnerID: new FormControl(''),
      consignorAddressStreet: new FormControl(''),
      consignorAddressCountry: new FormControl(''),
      consignorComment: new FormControl(''),
      consignorContactPersonName: new FormControl(''),
      consignorContactPersonTel: new FormControl(''),
      consignorContactPersonFax: new FormControl(''),
      consignorContactPersonEmail: new FormControl(''),
      freightPayerConsignorCustomerIdSgv: new FormControl(['']),
      freightPayerConsignorCustomerIdAuthorityOfCustomerId: new FormControl(['']),
      freightPayerConsignorAddressZipCode: new FormControl(['']),
      freightPayerConsignorVatId: new FormControl(['']),
      loadingPartyCustomerIdSgv: new FormControl(['']),
      loadingPartyCustomerIdAuthorityOfCustomerId: new FormControl(['']),
      loadingPartyAddressZipCode: new FormControl(['']),
      // Consignee
      consigneeCustomerIDCustomerIdAuthorityOfCustomerId: new FormControl(['']),
      consigneeCustomerID: new FormControl(''),
      consigneeName: new FormControl(['']),
      consigneeAddressZipCode: new FormControl(['']),
      consigneeAddressCity: new FormControl(['']),
      consigneeAddressStreet: new FormControl(['']),
      consigneeAddressCountry: new FormControl(['']),
      consigneeContactPersonName: new FormControl(''),
      consigneeComment: new FormControl(''),
      consigneeContactPersonTel: new FormControl(''),
      consigneeContactPersonFax: new FormControl(''),
      consigneeContactPersonEmail: new FormControl(''),
      freightPayerConsigneeCustomerIdSgv: new FormControl(''),
      freightPayerConsigneeCustomerIdAuthorityOfCustomerId: new FormControl(['']),
      freightPayerConsigneeAddressZipCode: new FormControl(['']),
      freightPayerConsigneeVatId: new FormControl(['']),
      unloadingPartyCustomerIdSgv: new FormControl(['']),
      unloadingPartyCustomerIdAuthorityOfCustomerId: new FormControl(['']),
      unloadingPartyAddressZipCode: new FormControl(['']),
    });
  }

  protected togglePartyInfoConsignorVisibility() {
    this.isPartyInfoConsignorVisible = !this.isPartyInfoConsignorVisible;
  }

  protected togglePartyInfoConsigneeVisibility() {
    this.isPartyInfoConsigneeVisible = !this.isPartyInfoConsigneeVisible;
  }

  public validate(): string[] {
    console.log('validate NewOrderConsignorConsigneeComponent');
    return [];
  }

  private getConsignorCustomerID(): FormControl {
    return this.formGroup.get('consignorCustomerID') as FormControl;
  }

  
  private getConsignorPartnerID(): FormControl {
    return this.formGroup.get('consignorPartnerID') as FormControl;
  }

  private getConsignorAddressStreet(): FormControl {
    return this.formGroup.get('consignorAddressStreet') as FormControl;
  }

  private getConsignorAddressCountry(): FormControl {
    return this.formGroup.get('consignorAddressCountry') as FormControl;
  }

  private getConsignorAddressCity() : FormControl {
    return this.formGroup.get('consignorCustomerID') as FormControl;
  }

  private getConsignorComment(): FormControl {
    return this.formGroup.get('consignorComment') as FormControl;
  }

  private getConsignorContactPersonName(): FormControl {
    return this.formGroup.get('consignorContactPersonName') as FormControl;
  }

  private getConsignorContactPersonTel(): FormControl {
    return this.formGroup.get('consignorContactPersonTel') as FormControl;
  }

  private getConsignorContactPersonFax(): FormControl {
    return this.formGroup.get('consignor') as FormControl;
  }

  private getConsignorContactPersonEmail(): FormControl {
    return this.formGroup.get('consignorContactPersonEmail') as FormControl;
  }

  private getFreightPayerConsignorCustomerIdSgv(): FormControl {
    return this.formGroup.get('freightPayerConsignorCustomerIdSgv') as FormControl;
  }

  private getFreightPayerConsignorCustomerIdAuthorityOfCustomerId(): FormControl {
    return this.formGroup.get('freightPayerConsignorCustomerIdAuthorityOfCustomerId') as FormControl;
  }

  private getFreightPayerConsignorAddressZipCode(): FormControl {
    return this.formGroup.get('freightPayerConsignorAddressZipCode') as FormControl;
  }

  private getFreightPayerConsignorVatId(): FormControl {
    return this.formGroup.get('freightPayerConsignorVatId') as FormControl;
  }

  private get LoadingPartyCustomerIdSgv(): FormControl {
    return this.formGroup.get('loadingPartyCustomerIdSgv') as FormControl;
  }

  private getLoadingPartyCustomerIdAuthorityOfCustomerId(): FormControl {
    return this.formGroup.get('loadingPartyCustomerIdAuthorityOfCustomerId') as FormControl;
  }

  private getLoadingPartyAddressZipCode(): FormControl {
    return this.formGroup.get('loadingPartyAddressZipCode') as FormControl;
  }

  private getConsigneeCustomerID(): FormControl {
    return this.formGroup.get('consigneeCustomerID') as FormControl;
  }

  private getConsigneeName(): FormControl {
    return this.formGroup.get('consigneeName') as FormControl;
  }

  private getConsigneePartnerID(): FormControl {
    return this.formGroup.get('consigneePartnerID') as FormControl;
  }

  private getConsigneeAddressStreet(): FormControl {
    return this.formGroup.get('consigneeAddressStreet') as FormControl;
  }

  private getConsigneeAddressCity(): FormControl {
    return this.formGroup.get('consigneeAddressCity') as FormControl;
  }

  private getConsigneeAddressCountry(): FormControl {
    return this.formGroup.get('consigneeAddressCountry') as FormControl;
  }

  private getConsigneeComment(): FormControl {
    return this.formGroup.get('consigneeComment') as FormControl;
  }

  private getConsigneeContactPersonName(): FormControl {
    return this.formGroup.get('consigneeContactPersonName') as FormControl;
  }

  private getConsigneeContactPersonTel(): FormControl {
    return this.formGroup.get('consigneeContactPersonTel') as FormControl;
  }

  private getConsigneeContactPersonFax(): FormControl {
    return this.formGroup.get('consigneeContactPersonFax') as FormControl;
  }

  private getConsigneeContactPersonEmail(): FormControl {
    return this.formGroup.get('consigneeContactPersonEmail') as FormControl;
  }

  private getFreightPayerConsigneeCustomerIdSgv(): FormControl {
    return this.formGroup.get('freightPayerConsigneeCustomerIdSgv') as FormControl;
  }
  private getFreightPayerConsigeeCustomerIdAuthorityOfCustomerId(): FormControl {
    return this.formGroup.get('freightPayerConsigeeCustomerIdAuthorityOfCustomerId') as FormControl;
  }
  private getFreightPayerConsigneeAddressZipCode(): FormControl {
    return this.formGroup.get('freightPayerConsigneeAddressZipCode') as FormControl;
  }
  private getFreightPayerConsigneeVatId(): FormControl {
    return this.formGroup.get('freightPayerConsigneeVatId') as FormControl;
  }

  private getUnloadingPartyCustomerIdSgv(): FormControl {
    return this.formGroup.get('unloadingPartyCustomerIdSgv') as FormControl;
  }

  private getLoadingPartyCustomerIdSgv(): FormControl {
    return this.formGroup.get('loadingPartyCustomerIdSgv') as FormControl;
  }

  private getUnloadingPartyCustomerIdAuthorityOfCustomerId(): FormControl {
    return this.formGroup.get('unloadingPartyCustomerIdAuthorityOfCustomerId') as FormControl;
  }
  private getUnloadingPartyAddressZipCode(): FormControl {
    return this.formGroup.get('unloadingPartyAddressZipCode') as FormControl;
  }

  onChangeLoadingPartyAddressZipCode($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeloadingPartyCustomerIdAuthorityOfCustomerId($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeLoadingPartyCustomerIdSgv($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsignorContactPersonEmail($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsignorContactPersonTel($event: Event) {
    throw new Error('Method not implemented.');
  }

  onChangeConsignorContactPersonFax($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsignorComment($event: Event) {
    throw new Error('Method not implemented.');
  }

  onChangeConsignorContactPersonName($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsignorAddressStreet($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsignorAddressCountry($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsignorPartnerID($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsignorCustomerID($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeFreightPayerConsignorCustomerIdSgv($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeFreightPayerConsignorCustomerIdAuthorityOfCustomerId($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeFreightPayerConsignorAddressZipCode($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeFreightPayerConsignorVatId($event: Event) {
    throw new Error('Method not implemented.');
  }

  onChangeUnloadingPartyAddressZipCode($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsigneeName($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeUnloadingPartyCustomerIdSgv($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeUnloadingPartyCustomerIdAuthorityOfCustomerId($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsigneeContactPersonTel($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsigneeContactPersonFax($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsigneeContactPersonEmail($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeFreightPayerConsigneeCustomerIdSgv($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeFreightPayerConsigneeCustomerIdAuthorityOfCustomerId($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeFreightPayerConsigneeAddressZipCode($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeFreightPayerConsigneeVatId($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsigneeComment($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsigneeAddressCountry($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsigneeAddressStreet($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsigneeAddressZipCode($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsigneeCustomerID($event: Event) {
    throw new Error('Method not implemented.');
  }
  onChangeConsigneeCustomerIDCustomerIdAuthorityOfCustomerId($event: Event) {
    throw new Error('Method not implemented.');
  }

}