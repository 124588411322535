// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span {
  display: table-cell;
  text-align: left;
  vertical-align: top;
  margin-bottom: 35px;
}

.modal-main {
  overflow: hidden;
  padding-bottom: 32px;
}

.train-data-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

input {
  caret-color: black;
}

.input-block {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  margin-top: 17px;
  margin-bottom: 17px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  margin-top: 17px;
  margin-bottom: 17px;
}
.input-block h3 {
  margin-bottom: 13px;
  grid-column: 2;
  margin-top: 20px;
}

h3 {
  margin-bottom: 13px;
  grid-column: 2;
  margin-top: 20px;
}

.input-block-complete {
  padding-right: 10px;
  grid-template-columns: max-content;
}
.input-block-complete .border-transport-up {
  height: 1px;
  border: solid 1px #c8cdd2;
  margin-top: 20px;
  margin-bottom: 6px;
}
.input-block-complete .border-transport-down {
  height: 1px;
  border: solid 1px #c8cdd2;
  margin-top: 3px;
  margin-bottom: 20px;
}

#misc-information {
  height: 85px;
  background-color: #f0f3f5;
  border: none;
  resize: none;
  height: 85px;
  background-color: #f0f3f5;
  border: none;
  resize: none;
}

.textarea-length {
  float: right;
  font-size: 12px;
  color: #646973;
  margin-right: 18px;
}

.error-block {
  margin-top: 80px;
  margin-bottom: 10px;
  text-align: right;
}

.textarea-length {
  float: right;
  font-size: 12px;
  color: #646973;
  margin-right: 18px;
}

.error-block {
  margin-top: 80px;
  margin-bottom: 10px;
  text-align: right;
}

.form-control-block {
  margin-top: 90px;
  margin-top: 90px;
}
.form-control-block div {
  float: right;
}
.form-control-block div {
  float: right;
}
.form-control-block button:last-of-type {
  margin-left: 30px;
}
.form-control-block button:last-of-type {
  margin-left: 30px;
}

.info-block {
  overflow-y: auto;
  max-height: calc(100vh - 595px);
  display: grid;
  gap: 0.5em;
}
.info-block .column {
  float: left;
}
.info-block .left {
  width: 45%;
}
.info-block .right {
  width: 55%;
}

.icon-block {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 15px;
  top: 30px;
  position: absolute;
  width: 24px;
  height: 24px;
  right: 15px;
  top: 30px;
}
.icon-block .icon {
  position: absolute;
}
.icon-block .icon {
  position: absolute;
}

.hide-icon {
  visibility: hidden;
  visibility: hidden;
}

.show-icon {
  visibility: visible;
  visibility: visible;
}

nav {
  margin-bottom: 60px;
  margin-bottom: 60px;
}

form .input-block {
  min-height: calc(100vh - 794px);
}
form .input-block:first-of-class {
  display: grid;
}
form .input-block {
  min-height: calc(100vh - 794px);
}
form .input-block:first-of-class {
  display: grid;
}
form .tab-hide {
  display: none;
}
form .tab-hide {
  display: none;
}

.cmp-mainnavigation {
  position: relative;
  top: 0;
  position: relative;
  top: 0;
}
@media screen and (max-width: 0px) {
  .cmp-mainnavigation {
    position: absolute;
    top: to-rem-sass(64);
    left: 0;
    z-index: 0;
    width: 100vw;
  }
  .cmp-mainnavigation > input[type=checkbox][id]:not(:checked) + label[for]::after {
    content: none;
  }
  .cmp-mainnavigation > input[type=checkbox][id]:not(:checked) ~ ul {
    display: block;
  }
}
@media screen and (max-width: 0px) {
  .cmp-mainnavigation {
    position: absolute;
    top: to-rem-sass(64);
    left: 0;
    z-index: 0;
    width: 100vw;
  }
  .cmp-mainnavigation > input[type=checkbox][id]:not(:checked) + label[for]::after {
    content: none;
  }
  .cmp-mainnavigation > input[type=checkbox][id]:not(:checked) ~ ul {
    display: block;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 200px auto;
}
.grid-container .grid-itemdiv {
  max-width: 702px;
  margin: auto;
}
.grid-container .grid-item .block-title {
  font-size: 1.1em;
  font-weight: bold;
}
.grid-container .grid-item .input-block {
  overflow-y: auto;
  display: grid;
  gap: 0.5em;
  margin: unset;
}
.grid-container .grid-item .input-block .input-field {
  width: 328px;
  margin: unset;
}
.grid-container .grid-item .input-block .small-input-block {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 0.5em;
}
.grid-container .grid-item .input-block .small-input-block .input-field {
  max-width: 149px;
}
.grid-container .grid-item .input-block-complete {
  overflow-y: overlay;
  max-height: calc(100vh - 451px);
  padding-right: 28px;
}
.grid-container .grid-item .input-block-complete .input-block {
  display: grid;
  gap: 0.5em;
}
.grid-container .grid-item .input-block-complete .input-block .input-field {
  width: 328px;
}
.grid-container .grid-item .input-block-complete .input-block .small-input-block {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 0.25em;
}
.grid-container .grid-item .input-block-complete .input-block .small-input-block .input-field {
  max-width: 16.5vh;
}
.grid-container .grid-item .tab-header {
  margin-bottom: -30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 17px;
}
.grid-container .grid-item .tab-header .tab-title {
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 30px;
}
.grid-container .grid-item .tab-header .RequiredField {
  font-size: 1em;
  margin-bottom: 35px;
}
.grid-container .input-block {
  display: grid;
  gap: 0.5em;
}
.grid-container .input-block .input-field {
  width: 328px;
}
.grid-container .input-block .small-input-block {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 0.25em;
}
.grid-container .input-block .small-input-block .input-field {
  max-width: 16.5vh;
}

.tab-header {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 17px;
}
.tab-header .tab-title {
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 30px;
}
.tab-header .RequiredField {
  font-size: 1em;
  margin-bottom: 35px;
}

.right-aligned {
  display: flex;
  justify-content: flex-end;
  display: flex;
  justify-content: flex-end;
}

.tabs-item-selected a {
  color: #ec0016 !important;
}
.tabs-item-selected a {
  color: #ec0016 !important;
}

.tabs-item-valid::before {
  border: solid 1.5px #878c96 !important;
  background-color: #878c96 !important;
  color: #fdfdfd !important;
  border: solid 1.5px #878c96 !important;
  background-color: #878c96 !important;
  color: #fdfdfd !important;
}

.tabs-item-selected::before {
  border: solid 1.5px #ec0016 !important;
  background-color: #ec0016 !important;
  color: #fdfdfd !important;
  border: solid 1.5px #ec0016 !important;
  background-color: #ec0016 !important;
  color: #fdfdfd !important;
}

.tabs-item-filled::before {
  background-color: #878c96 !important;
  color: #fdfdfd !important;
  background-color: #878c96 !important;
  color: #fdfdfd !important;
}

.tabs {
  counter-reset: my-tabs-counter;
  counter-reset: my-tabs-counter;
}
.tabs .tabs-item {
  padding: 0 0 20px 20px;
  position: relative;
  max-height: 22px;
}
.tabs .tabs-item::before {
  content: counter(my-tabs-counter);
  counter-increment: my-tabs-counter;
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  left: -9.5px;
  top: 0;
  border: solid 1.5px #d7dce1;
  background-color: #fff;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #878c96;
  text-align: center;
  font-family: "DB Screen Sans", Roboto, "Helvetica Neue", sans-serif;
}
.tabs .tabs-line {
  padding: 0 0 20px 20px;
  margin-top: 4px;
  margin-bottom: 4px;
  border-left: 2px solid #d7dce1;
  position: relative;
  height: 42px;
}
.tabs .tabs-item {
  padding: 0 0 20px 20px;
  position: relative;
  max-height: 22px;
}
.tabs .tabs-item::before {
  content: counter(my-tabs-counter);
  counter-increment: my-tabs-counter;
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  left: -9.5px;
  top: 0;
  border: solid 1.5px #d7dce1;
  background-color: #fff;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #878c96;
  text-align: center;
  font-family: "DB Screen Sans", Roboto, "Helvetica Neue", sans-serif;
}
.tabs .tabs-line {
  padding: 0 0 20px 20px;
  margin-top: 4px;
  margin-bottom: 4px;
  border-left: 2px solid #d7dce1;
  position: relative;
  height: 42px;
}

.disabled-input {
  pointer-events: none;
  opacity: 0.4;
  pointer-events: none;
  opacity: 0.4;
}

.span-two {
  grid-column: 1/span 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.span-two textarea {
  width: 100%;
}

.hidden-overflow {
  overflow: unset;
}

.error-block {
  margin-top: 80px;
  margin-bottom: 10px;
  text-align: right;
}

.flex-row {
  max-height: 72px;
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.button-group {
  margin-top: 17px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
