import { Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Seal } from "../../../../models/api-railorder";

@Component({
  selector: 'app-sealing-list',
  templateUrl: './sealing-list.component.html',
  styleUrls: ['./sealing-list.component.scss']
})
export class SealingListComponent {

  @Input() formGroup: FormGroup;

  constructor(private fb: FormBuilder) {

  }

  ngOnInit(): void {
    if (this.sealingList.length === 0) {
      this.addNewLine();
    }
  }

  protected get sealingList(): FormArray {
    return this.formGroup?.get('sealingList') as FormArray;
  }

  protected addNewLine(seal?: Seal): void {
    const itemGroup: FormGroup = this.fb.group({});
    if (seal) {
      itemGroup.addControl('test_1', new FormControl(''));      
    } else {
      itemGroup.addControl('test_1', new FormControl());      
    }
    this.sealingList.push(itemGroup);    
  }

  protected removeLine(idx: number): void {
    if (this.sealingList.length > 1) {
      this.sealingList.removeAt(idx);
    }   
  }
}