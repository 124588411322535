import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DocumentationComponent } from "./documentation.component";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { MatTabsModule } from "@angular/material/tabs";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { DocumentationService } from "./documentation.service";

@NgModule({
  declarations: [
    DocumentationComponent
  ],
  imports: [
    CommonModule, 
    MatDialogModule,
    TranslateModule.forChild(),
    MatTabsModule,
    PdfViewerModule
  ],
  providers: [
    DocumentationService,
    {
      provide: MatDialogRef,
      useValue: {}
    }
  ]
})
export class DocumentationModule {

}
