import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EnvService } from 'src/app/shared/services/env/env.service';
import { initialRailOrder, RailOrder } from '../models/api-railorder';
import { ApiUrls } from 'src/app/shared/enums/api-urls.enum';

@Injectable({
  providedIn: 'root'
})
export class NewOrderService {
  
  private backendUrl: string;

  constructor(
    private httpClient: HttpClient,
    private env: EnvService
  ) {
    this.backendUrl = this.env?.backendUrlOm;
    if (!this.backendUrl) {
     // console.info("No environment setting for backendUrl found!");
    }
  }

  private httpHeaders = {
    headers: new HttpHeaders({
      'Requested-By': 'OM-Web-Frontend'
    })
  }
  
  
  public getOrder(orderId: number): Observable<RailOrder> {
    
    if (!this.backendUrl || (!orderId)) {
      console.warn('Backend URL is not configured. Show empty dialog.');
      return this.getEmptyObject();
    }
    const url = ApiUrls.RAILORDERS.replace('{orderId}',`${orderId}`);
    const uri = this.backendUrl + url;
    return this.httpClient.get<RailOrder>(uri, this.httpHeaders);

  }

  private getEmptyObject( ): Observable<RailOrder> {

    return of(initialRailOrder())
  }


}
