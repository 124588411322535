// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .elm-button {
  height: 51px !important;
}

input:not([type=checkbox]) {
  background-color: #f0f3f5;
  height: 50px;
}

.arrow-div {
  margin: 1em 0 0 0;
}

.elm-select {
  width: 100%;
  height: 51px;
  padding: 0 0 6px 5px;
  background-color: #F0F3F5;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
