export enum ApiUrls {
    TRAINS = '/trains/search',
    TRAIN_DETAIL = '/trains/{train-number}/{prod-date}',
    TRAIN_CHAIN_DETAIL = '/train-chains/{trainChainId}/{trainChainDate}',
    // TRAIN_CHAIN_DETAIL = '/train-chains?trainChainId={trainChainId}&trainChainDate={trainChainDate}',
    TRAINS_TRACKING_HISTORY = '/trains/{train-number}/{prod-date}/tracking-history',
    TRAIN_CHAINS_TRACKING_HISTORY ='/train-chains/{trainChainId}/{trainChainDate}/tracking-history',
    // TRAIN_CHAINS_TRACKING_HISTORY ='/train-chains/tracking-history?trainChainId={trainChainId}&trainChainDate={trainChainDate}',
    TRAIN_CONNECTIONS = '/train-connections/search',
    COMMERCIAL_LOCATIONS = '/commercial-locations?query={query}',
    ORDERS_COMMERCIAL_LOCATIONS = '/train-orders/commercial-locations?query={query}&stationType={stationType}',
    ORDER_TEMPLATES_COMMERCIAL_LOCATIONS = '/order-templates/commercial-locations?query={query}&stationType={stationType}',
    TRAINS_INFRASTRUCTURE_LOCATIONS = '/trains/infrastructure-locations?query={query}&stationType={stationType}',
    TRAINS_ORDERS_INFRASTRUCTURE_LOCATIONS = '/train-connections/infrastructure-locations?query={query}&stationType={stationType}',
    PRODUCTION_LOCATIONS = '/commercial-locations/{objectKeyAlpha}/{objectKeySequence}/production-locations?relationCode={relationCode}',
    NEW_ORDERS = '/train-orders',
    DANGEROUS_GOODS = '/cargo/dangerous-goods',
    GOODS = '/cargo/goods',
    CUSTOMERS = '/customers',
    CUSTOMERS_SITES = '/customers/{sgv-nr}/sites',
    HOLIDAYS = '/calendar/holidays',
    ORDERS = '/train-orders/search',
    ORDERS_PROGRAM = '/train-orders/program',
    ORDER_DETAILS = '/train-orders/{order-number}',
    ORDER_CANCEL = '/train-orders/{order-number}/cancel',
    ORDER_CANCEL_BY_TRAIN = '/train-orders/cancel?trainNumber={trainNumber}&prodDate={prodDate}',
    ORDER_CANCEL_BY_CHAIN = '/train-orders/cancel?trainChainId={trainChainId}&trainChainDate={trainChainDate}',
    ORDER_DETAILS_BY_TRAIN = '/train-orders?trainNumber={train-number}&prodDate={prod-date}&profiles={active-profiles}',
    ORDER_DETAILS_BY_CHAIN = '/train-orders?trainChainId={trainChainId}&trainChainDate={trainChainDate}&profiles={active-profiles}',
    RAILORDER_DETAILS_BY_TRAIN = '/rail-orders?trainNumber={train-number}&prodDate={prod-date}',
    RAILORDER_DETAILS_BY_CHAIN = '/rail-orders?trainChainId={trainChainId}&trainChainDate={trainChainDate}',
    ORDER_REDUCE = '/train-orders/{order-number}/reduce',
    TRAINS_TRAIN_NUMBER = '/trains/train-number',
    SUPPLIERS = '/suppliers',
    WORKING_DIRECTION = '/working-directions',
    ORDER_TEMPLATES = '/order-templates',
    ORDER_TEMPLATES_TEMPLATE_ID = '/order-templates/{template-id}',
    ORDER_TEMPLATES_SEARCH = '/order-templates/search',
    MARKETSEGMENTS = '/market-segments',
    BORDERS = '/borders',
    COMMERCIAL_SERVICES = '/commercial-services',
    TOM_GROUPS = '/tom-groups',
    WAGON_TYPES = '/short-wagon-types',
    DANGEROUS_GOODS_CLASSES = '/cargo/dangerous-goods/classes',
    AUTHORIZATION = '/authorization',
    SYSTEMINFORMATION_MANUAL = '/systeminformation/manual',
    SYSTEMINFORMATION_RELEASE = '/systeminformation/release',
    TRAIN_CHAIN_IDS = '/train-chains/ids',
    // T&T
    RAILORDER_TRACKING_HISTORY= '/rail-orders/{orderId}/wagons/{wagonNumber}/tracking-history',
    VEHICLE='/vehicle/{vehicleNumber}',
    RAILORDERS='/rail-orders/{orderId}'
}