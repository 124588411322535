import { AuthConfig } from "angular-oauth2-oidc";
import { EnvServiceFactory } from "../env/env.service.provider";

const envService = EnvServiceFactory();

// Use HashLocationStrategy for routing?
export const useHash = false;

// Set this to true, to use silent refresh; otherwise the example
// uses the refresh_token via an AJAX coll to get new tokens.
export const useSilentRefreshForCodeFlow = false;

export const authCodeFlowConfig: AuthConfig = {
    // issuer: 'https://ssocargo-mts.test.service.deutschebahn.com:8443/f5-oauth2/v1/',
    issuer: envService.issuer,
    strictDiscoveryDocumentValidation: false,
    skipIssuerCheck : true,
    oidc: true,
    requireHttps: false,
  
    // URL of the SPA to redirect the user to after login
    redirectUri: 'http://localhost:4200/gzp/trainorder/home',
    // redirectUri: envService.redirectUri,
    // clientId: '1f18a971b6f8528406a8a53d1f8d02a0ec57b00c63d06463',
    clientId: envService.clientId,

    // Just needed if your auth server demands a secret. In general, this
    // is a sign that the auth server is not configured with SPAs in mind
    // and it might not enforce further best practices vital for security
    // such applications.
    // dummyClientSecret: 'secret',
  
    responseType: 'code',
  
    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    // The api scope is a usecase specific one
    // scope: 'openid profile email api',
    scope: 'openid',
    // scope: useSilentRefreshForCodeFlow
    //   ? 'openid profile email api'
    //   : 'openid profile email offline_access api',
  
    // ^^ Please note that offline_access is not needed for silent refresh
    // At least when using idsvr, this even prevents silent refresh
    // as idsvr ALWAYS prompts the user for consent when this scope is
    // requested
  
    // This is needed for silent refresh (refreshing tokens w/o a refresh_token)
    // **AND** for logging in with a popup
    silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,
  
    useSilentRefresh: useSilentRefreshForCodeFlow,
  
    showDebugInformation: false,
  
    sessionChecksEnabled: false,
  
    timeoutFactor: 0.7,
    disablePKCE: true,
  
    clearHashAfterLogin: true,
  };

