import { Component } from '@angular/core';
import { ModalWindows } from '../../modal-windows/modal-windows';

@Component({
  selector: 'app-confirmation-order-template',
  templateUrl: './confirmation-order-template.component.html',
  styleUrls: ['./confirmation-order-template.component.scss']
})
export class ConfirmationOrderTemplateComponent {

  constructor(
    private modalWindows: ModalWindows
    ){
    
}

closeModals(){
    this.modalWindows.closeAllModalWindows();
}
}
