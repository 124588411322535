import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from './auth-config';
import { filter } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';
import { OAuthSuccessEvent } from 'angular-oauth2-oidc/events';
import { TokenResponse } from 'angular-oauth2-oidc/types';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { PermissionService } from '../../permission/PermissionService';
import { TrainorderService } from 'src/app/trainorder/services/trainorder.service';

export interface bearerToken {
  lso_username: string,
  exp: string,
  iat: string
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
    
  constructor(
    private oauthService: OAuthService,
    private storageService: LocalStorageService,
    private permissionService: PermissionService
  ) { }

  public tryLogin(redirectUri?: string): Promise<boolean> {
    this.storageService.clearAuthorizationStorage();
    this.clearTrainorderStorage();
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.customQueryParams = {
      'token_content_type': 'jwt'
    };

    // after login always show landing page
    const arr = location.href.split("/");
    redirectUri = `${arr[0]}//${arr[2]}`;
    this.oauthService.redirectUri = redirectUri;
    
    // for loging: log every event
    // this.oauthService.events.subscribe((_) => {
    //   console.log(_);
    // });

    this.oauthService.events.pipe(filter((e) => e.type === 'token_received')).subscribe((_) => {
      this.onTokenReceived();
    });

    // this.oauthService.events.subscribe(e => {
    //   console.log("Event", e);
    //   if(e instanceof OAuthInfoEvent) {
    //     console.log("Info Event: ", e);
    //   }else if(e instanceof OAuthErrorEvent) {
    //     console.log("Error Event: ", e);
    //   }
    //   if(e instanceof OAuthSuccessEvent) {
    //     console.log("Success Event: ", e);
    //   }
    // });

    this.oauthService.events.pipe(filter((e) => e.type === 'token_error'
      || e.type === 'session_terminated'
      || e.type === 'session_error'
      || e.type === 'token_validation_error'
      || e.type === 'silent_refresh_timeout'
      || e.type === 'invalid_nonce_in_state'
      || e.type === 'silent_refresh_error')
    ).subscribe((_) => {
      this.onErrorEvent(_.type);
    });

    this.oauthService.events.pipe(filter((e) => e.type === 'token_refresh_error')).subscribe((_) => {
      this.tryLogin();
    });

    const success = this.oauthService.loadDiscoveryDocumentAndLogin()
        .then((_) => {
          this.watchTokenBeforeLogout();
          return _;
        })
        .catch((_) => {
          this.oauthService.tryLogin();
          return _;
        });

    this.oauthService.setupAutomaticSilentRefresh({}, 'access_token');
    return success;
  }

  /**
   * ***************** START Automatic redirect to login, if no valid token is present *****************
   * Cargo SSO does not return new token after about an hour. After 55 Minutes a process is started
   * to watch (every 5 seconds), if the user still has a valid token. If there is no more valid token, 
   * the user will be redirected to login.
   */
  private watchTokenBeforeLogout(time: number = 1000 * 60 * 55) {
    const to = setTimeout(() => {this.watcher()}, time);
  }

  private watcher() {
    // console.log("watcher.this", this);
    const to = setTimeout(() => {this.every5Seconds()}, 5000);
    // console.log("to", to);
  }

  private every5Seconds() {
    // console.log("every5Seconds.this", this);
    if(this.hasAccessToken() && this.isAccessTokenValid()) {
      this.watcher();
    } else {
      this.tryLogin();
    }
  }
  // *****************  END Automatic redirect to login, if no valid token is present  *****************

  public isAccessTokenValid(): boolean {
    const expiresAt = this.oauthService.getAccessTokenExpiration() as number | null;
    if (expiresAt == null)
      return false;

    const tokenValidTime = expiresAt - Date.now() - 30000;
    return tokenValidTime > 0;
  }

  public logoutAndTryLogin() {
    this.oauthService.events.pipe(filter((e) => e.type === 'logout')).subscribe((_) => {
      // if (window.location.search && window.location.search.length > 0) window.location.search = '';
      this.tryLogin();
    });
    this.oauthService.logOut(true);
  }

  public hasAccessToken(): boolean {
    return this.oauthService.getAccessToken() != null;
  }

  /**
   * clears filter criteria for trains list, orders list and ordertemplates list from storage
   */
  public clearTrainorderStorage() {
    sessionStorage.removeItem(TrainorderService.filterCriteriaTrainsKey);
    sessionStorage.removeItem(TrainorderService.filterCriteriaOrdersKey);
    sessionStorage.removeItem(TrainorderService.filterCriteriaOrdertemplatesKey);
  }

  public onErrorEvent(type: string) {
    this.oauthService.refreshToken().catch(_ => {
      console.error("onErrorEvent [EVENT:", type, "]");
      this.tryLogin();
    });
  }

  private onTokenReceived() {
    this.permissionService.loadPermissions4User();

    const token = jwtDecode<bearerToken>(this.oauthService.getAccessToken());
    const username = token.lso_username;
    /*
    console.log("now: ", new Date());
    console.log("iat: ", new Date(Number(token.iat) * 1000));
    console.log("exp: ", new Date(Number(token.exp) * 1000));
    */
    this.storageService.setUsername(username);
  }

  /**
   *  Try to get new access token. If not successfull then try to login.
   * 
   * @returns Promise<boolean>
   */
  public onNotValidAccessToken(): Promise<boolean> {
    return this.requestToken().then(b => {
      if (!b) return this.tryLogin();
      return true;
    });
  }

  public requestToken(): Promise<boolean> {
    this.oauthService.configure(authCodeFlowConfig);
    return this.oauthService.loadDiscoveryDocument().then((event: OAuthSuccessEvent) => {
      return this.oauthService.refreshToken().then(
        (tr: TokenResponse) => {
          this.oauthService.setupAutomaticSilentRefresh({}, 'access_token');
          this.watcher();
          return true;
        },
        (event: any) => {
          return false;
        }
      ).catch((event: any) => {
        return false;
      }
      );
    }).catch(_ => {
      console.error("load discovery document faild: ", _.type);
      console.error(_);
      return false;
    });
  }
}
