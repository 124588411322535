import { NgModule } from '@angular/core';
import { NewOrderConsignorConsigneeComponent } from './new-order-consignor-consignee.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    NewOrderConsignorConsigneeComponent,
  ],
  imports: [SharedModule],
  exports: [
    NewOrderConsignorConsigneeComponent,
  ]

})
export class NewOrderConsignorConsigneeModule { 
  
}