export const RAILORDER_TRANSPORTATIONTYPE_LISTNAME = 'RAILORDER_TRANSPORTATIONTYPE_LIST';
export const RAILORDER_TYPE_OF_TRANSPORT_CODE_LISTNAME = 'RAILORDER_TYPE_OF_TRANSPORT_CODE_LIST';
export const RAILORDER_DANGEROUSGOOD_LAW_LISTNAME = 'RAILORDER_DANGEROUSGOOD_LAW_LIST'
export const RAILORDER_CORE_CONFIGURATION_LISTNAME = 'RAILORDER_CORE_CONFIGURATION'
export const RAILORDER_CORE_CONFIGURATION_KEY_DATA_LISTNAME = 'RAILORDER_CORE_CONFIGURATION_KEY_DATA'
export const RAILORDER_AUTHORITYLIST_LISTNAME = 'RAILORDER_AUTHORITYLIST'
export const RAILORDER_CONSIGNMENT_TYPES_LISTNAME = 'RAILORDER_CONSIGNMENT_TYPES'
export const RAILORDER_CONSIGNOR_DECLARATION_CODES_LISTNAME = 'RAILORDER_CONSIGNOR_DECLARATION_CODES'
export const RAILORDER_COUNTRY_LISTNAME = 'RAILORDER_COUNTRY_LIST'
export const RAILORDER_SERVICE_SERVICE_TYPES_LISTNAME ='RAILORDER_SERVICE_SERVICE_TYPES'
export const RAILORDER_SERVICE_PAYMENTMETHOD_TYPES_LISTNAME ='RAILORDER_SERVICE_PAYMENTMETHOD_TYPES'
export const RAILORDER_COMMERCIAL_CURRENCY_LISTNAME='RAILORDER_COMMERCIAL_CURRENCY'
export const RAILORDER_COMMERCIAL_SPECIFICATION_LISTNAME = 'RAILORDER_COMMERCIAL_SPECIFICATION_LIST'


export interface KeyValuePair {
  key: string;
  value: any;
}

export interface RailorderCountry {
  "ISOCode": string,
  "Description"?: string,
  "UICCountryCode": number,
  "Alphacode"?: string
}