// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field {
  width: 359px;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 0px 5px 0px 5px;
}

.hide-icon {
  visibility: hidden;
}

.show-icon {
  visibility: visible;
}

.icon-block {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 15px;
  top: 32px;
}
.icon-block .icon {
  position: absolute;
}

.remove-icon {
  visibility: hidden;
}

input:focus ~ .icon-block .search-icon {
  visibility: hidden;
}

input:focus ~ .icon-block .remove-icon {
  visibility: unset;
}

.icon-block {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 15px;
  top: 32px;
}
.icon-block .icon {
  position: absolute;
}

.example-additional-selection {
  opacity: 0.75;
  font-size: 0.75em;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
