import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SectionName } from '../../enums/order-enums';
import { RailOrder } from '../../models/api-railorder';
import { NewOrderMainComponent } from '../../new-order-main/new-order-main.component';
import { SectionBase } from '../section.base';
import { FormControl, FormGroup } from '@angular/forms';
import { DynamicStorageService } from '../../service/dynamic-session-storage.service';
import { KeyValuePair } from '../../models/api-dynamic-storage';
import { RAILORDER_CONSIGNMENT_TYPES_LISTNAME, RAILORDER_TYPE_OF_TRANSPORT_CODE_LISTNAME, RAILORDER_TRANSPORTATIONTYPE_LISTNAME, RAILORDER_DANGEROUSGOOD_LAW_LISTNAME, RAILORDER_CORE_CONFIGURATION_LISTNAME, RAILORDER_CORE_CONFIGURATION_KEY_DATA_LISTNAME } from '../../models/api-dynamic-storage';

@Component({
  selector: 'app-new-order-transport',
  templateUrl: './new-order-transport.component.html',
  styleUrls: ['../../new-order-main/new-order-main.component.scss', './new-order-transport.component.scss']
})
export class NewOrderTransportComponent extends SectionBase implements OnChanges {

  @Input() currentSectionName: SectionName;
  @Input() railOrder: RailOrder;

  protected SectionName = SectionName;
  protected parent: NewOrderMainComponent;
  protected formGroup: FormGroup;
  protected transportationTypeList: KeyValuePair[] = [];
  protected typeOfTransportCodeList: KeyValuePair[] = [];
  protected dangerousgoodLawList: KeyValuePair[] = [];
  protected coreConfigurationList: KeyValuePair[] = [];
  protected consignmentTypesList: KeyValuePair[] = [];
  protected filteredCoreConfigurationList: KeyValuePair[] = [];

  constructor(private injector: Injector, private dynamicStorageService: DynamicStorageService) {
    super();
    this.parent = this.injector.get<NewOrderMainComponent>(NewOrderMainComponent);
    this.createForm();
    this.loadLists();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['railOrder']) {
      const railOrder: RailOrder = changes['railOrder'].currentValue;
      if (railOrder) {
        this.setFormValues(railOrder);
      }
    }
  }

  private createForm(): void {
    this.formGroup = new FormGroup({
      transportationType: new FormControl(''),
      typeOfTransportCode: new FormControl(''),
      dangerousgoodLaw: new FormControl(''),
      coreConfiguration: new FormControl(''),
      orderedTrainReference: new FormControl(''),
      customsDataCustomsDescriptor: new FormControl(''),
      consignmentNoteTypeOfConsignment: new FormControl('')
    });
  }

  private get transportationType(): FormControl {
    return this.formGroup.get('transportationType') as FormControl;
  }

  private get typeOfTransportCode(): FormControl {
    return this.formGroup.get('typeOfTransportCode') as FormControl;
  }

  private get dangerousgoodLaw(): FormControl {
    return this.formGroup.get('dangerousgoodLaw') as FormControl;
  }

  private get coreConfiguration(): FormControl {
    return this.formGroup.get('coreConfiguration') as FormControl;
  }

  private get orderedTrainReference(): FormControl {
    return this.formGroup.get('orderedTrainReference') as FormControl;
  }

  private get customsDataCustomsDescriptor(): FormControl {
    return this.formGroup.get('customsDataCustomsDescriptor') as FormControl;
  }

  private get consignmentNoteTypeOfConsignment(): FormControl {
    return this.formGroup.get('consignmentNoteTypeOfConsignment') as FormControl;
  }

  private loadLists(): void {
    this.dynamicStorageService.retrieveKeyValueList(RAILORDER_TRANSPORTATIONTYPE_LISTNAME)
      .subscribe({
        next: data => this.transportationTypeList = data || [],
        error: err => console.error(`Failed to load transportation type list: ${err}`)
      });

    this.dynamicStorageService.retrieveKeyValueList(RAILORDER_TYPE_OF_TRANSPORT_CODE_LISTNAME)
      .subscribe({
        next: data => this.typeOfTransportCodeList = data || [],
        error: err => console.error(`Failed to load type of transport code list: ${err}`)
      });

    this.dynamicStorageService.retrieveKeyValueList(RAILORDER_DANGEROUSGOOD_LAW_LISTNAME)
      .subscribe({
        next: data => this.dangerousgoodLawList = data || [],
        error: err => console.error(`Failed to load dangerous good law list: ${err}`)
      });

    this.dynamicStorageService.retrieveKeyValueList(RAILORDER_CORE_CONFIGURATION_LISTNAME)
      .subscribe({
        next: data => this.coreConfigurationList = data || [],
        error: err => console.error(`Failed to load core configuration list: ${err}`)
      });

    this.dynamicStorageService.retrieveKeyValueList(RAILORDER_CONSIGNMENT_TYPES_LISTNAME)
      .subscribe({
        next: data => this.consignmentTypesList = data || [],
        error: err => console.error(`Failed to load consignment types list: ${err}`)
      });
  }

  setFormValues(railOrder: RailOrder): void {
    this.transportationType.setValue(railOrder?.consignee?.customerId?.sgv);
    this.typeOfTransportCode.setValue(railOrder?.typeOfTransportCode);
    this.dangerousgoodLaw.setValue(railOrder?.dangerousGoodLaw);
    this.coreConfiguration.setValue(railOrder?.coreConfiguration);
    this.orderedTrainReference.setValue(railOrder?.orderedTrainReference);
    this.customsDataCustomsDescriptor.setValue(railOrder?.customsData?.customsDescriptor);
    this.consignmentNoteTypeOfConsignment.setValue(railOrder?.consignmentNote?.typeOfConsignment);
  }

  public onChangeOrder(): void {
    // Implement this method based on your requirements
  }

  public validate(): string[] {
    console.log('validate consignor-consignee section');
    return [];
  }

  onChangeTypeOfTransportCode($event: Event): void {
    const typeOfTransportCodeKeyFilter = ($event.target as HTMLSelectElement).value;
    console.log('Selected Type of Transport Code:', typeOfTransportCodeKeyFilter);

    this.dynamicStorageService.retrieveConfigurationList(RAILORDER_CORE_CONFIGURATION_KEY_DATA_LISTNAME)
      .subscribe({
        next: coreKeyData => {
          this.filteredCoreConfigurationList = [];
          if (coreKeyData && typeOfTransportCodeKeyFilter) {
            const filteredKeyData = coreKeyData.filter(({ typeOfTransportCodeKey }) => typeOfTransportCodeKey === typeOfTransportCodeKeyFilter);
            this.filteredCoreConfigurationList = filteredKeyData.map(({ productId, value }) => ({ key: productId, value }));
          } else {
            console.warn('No valid core key data or typeOfTransportCodeKeyFilter found.');
          }
          console.log('Filtered Core Configuration List:', this.filteredCoreConfigurationList);
        },
        error: err => console.error(`Failed to retrieve core configuration key data: ${err}`)
      });
  }

  onChangeCustomsDataCustomsDescriptor($event: Event): void {
    // Implement this method based on your requirements
  }

  onChangeOrderedTrainReference($event: Event): void {
    // Implement this method based on your requirements
  }
}
